interface ImageParameters {
  w?: number;
  h?: number;
  dps?: number;
  fit?: string;
  rect?: string;
  'fp-x'?: number;
  'fp-y'?: number;
  'fp-z'?: number;
  fm?: string;
  q?: number;
  lossless?: boolean;
  auto?: 'format';
}

export default function imageUrlBuilder (
  url: string,
  params: ImageParameters
): string | null {
  if (!url) return null

  return Object.keys(params)
    .reduce(
      (acc, key) => `${acc}${key}=${params[key as keyof ImageParameters]}&`,
      `${url}?`
    )
    .slice(0, -1)
}
