import { Destination, DestinationPage } from '@/models/Destination'
import { metadataElements, openGraphElements } from './customElements'
import httpClient from './httpClient'

const elements = [
  'title',
  'body',
  'image',
  'alternate_text',
  'path',
  ...openGraphElements,
  ...metadataElements
]

const getDestinations = () =>
  httpClient.get<DestinationPage>('/destination_page', {
    params: {
      depth: 2,
      elements: [...elements, 'destinations'].join(',')
    }
  }).then(res => {
    if (Object.keys(res.data).length === 0) {
      throw new Error('not found')
    }
    return res
  })

const getDestination = (slug: string) =>
  httpClient.get<Destination>('', {
    params: {
      'system.type': 'destination',
      'elements.path': slug,
      depth: 5,
      limit: 1
    }
  })

export { getDestination, getDestinations }
