
import { computed, defineComponent } from 'vue'
import router from '@/router'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import CardScroll from '@/components/CardScroll.vue'
import DestinationCard from '@/components/DestinationCard.vue'
import { getDestination } from '@/kentico-api/destinations.api'
import useSwrv from 'swrv/dist/use-swrv'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import { useHead } from '@vueuse/head'
import dayjs from 'dayjs'
import RouteCard from '@/components/RouteCard.vue'

export default defineComponent({
  components: {
    PageHeader,
    DestinationCard,
    GenericError,
    CardScroll,
    RouteCard
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const { data, error } = useSwrv(() => props.slug, getDestination,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      })
    const destination = computed(() => data.value?.data)
    const imageUrl = computed(() =>
      destination.value
        ? imageUrlBuilder(destination.value.image.url, {
          auto: 'format',
          w: 541
        })
        : null
    )
    const routes = computed(() =>
      destination.value?.routesTo.map(route => ({
        name: route.locations,
        number: route.number,
        id: route.routeItineraryId
      }))
    )

    const goToRouteDescription = (
      routeNumber: string,
      routeDescriptionSlug: string,
      key: string
    ) => {
      const now = dayjs()
      router.push(
        {
          name: 'Route',
          params:
          {
            routeNumber,
            routeName: routeDescriptionSlug !== '' ? routeDescriptionSlug : key,
            forDate: now.format('YYYY-MM-DD')
          }
        }
      )
    }

    useHead({
      title: computed(() => destination.value?.metadataPageTitle || 'Destination'),
      meta: [
        {
          name: 'description',
          content: computed(() => destination.value?.metadataMetaDescription || ''),
          key: 'description'
        },
        {
          name: 'keywords',
          content: computed(() => destination.value?.metadataMetaKeywords || ''),
          key: 'keywords'
        },
        {
          name: 'og:description',
          content: computed(() => destination.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => destination.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}destinations/${props.slug}`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() =>
            destination.value && destination.value.openGraphOgImage.length > 0
              ? imageUrlBuilder(destination.value.openGraphOgImage[0].url,
                { w: 800, auto: 'format' })
              : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:locale',
          content: computed(() => destination.value?.openGraphOgLocale || ''),
          key: 'ogLocale'
        }
      ]
    })
    return {
      goToRouteDescription,
      destination,
      imageUrl,
      error,
      routes
    }
  }
})
