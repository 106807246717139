
export const openGraphElements = [
  'open_graph__og_description',
  'open_graph__og_locale',
  'open_graph__og_title',
  'open_graph__og_type',
  'open_graph__og_url',
  'open_graph__og_image'
]

export const metadataElements = [
  'metadata__page_title',
  'metadata__meta_description',
  'metadata__meta_keywords'
]
