
import { computed, defineComponent } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import DestinationCard from '@/components/DestinationCard.vue'
import { getDestinations } from '@/kentico-api/destinations.api'
import useSwrv from 'swrv/dist/use-swrv'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'

export default defineComponent({
  components: { PageHeader, DestinationCard, GenericError },
  name: 'destinations',
  setup () {
    const { data, error } = useSwrv('/destinations', getDestinations,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      })
    const destinationsPage = computed(() => data.value?.data)

    useHead({
      title: computed(() => destinationsPage.value?.metadataPageTitle || ''),
      meta: [
        {
          name: 'keywords',
          content: computed(() => destinationsPage.value?.metadataMetaKeywords || ''),
          key: 'keywords'
        },
        {
          name: 'description',
          content: computed(() => destinationsPage.value?.metadataMetaDescription || ''),
          key: 'description'
        },
        {
          name: 'og:description',
          content: computed(() => destinationsPage.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => destinationsPage.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}destinations`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() =>
            destinationsPage.value && destinationsPage.value.openGraphOgImage.length > 0
              ? imageUrlBuilder(destinationsPage.value.openGraphOgImage[0].url,
                { w: 800, auto: 'format' })
              : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:locale',
          content: computed(() => destinationsPage.value?.openGraphOgLocale || ''),
          key: 'ogLocale'
        }
      ]
    })
    return {
      destinationsPage,
      error
    }
  }
})
