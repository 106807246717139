
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    viewAllLocation: [String, Object] as PropType<RouteLocationRaw>,
    viewAllLabel: String
  }
})
